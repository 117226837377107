.dropzone {
  flex: 1;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  padding: 20px;
  border-width: 2px;
  border-radius: 4px;
  border-color: #d6dde9;
  border-style: solid;
  background-color: #e8eff9;
  min-height: 200px;
  color: #7e858d;
  outline: none;
  transition: border 0.24s ease-in-out;

  &.active {
    border-color: #2196f3;
  }

  &:focus {
    border-color: #2196f3;
  }
  &:disabled {
    opacity: 0.6;
  }
}
