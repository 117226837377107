@import "styles/index";

.pageTitle {
  margin-bottom: 32px;
  text-align: center;
  font-weight: 500;
  font-size: 32px;
}

.loginForm {
  display: flex;
  flex-direction: column;
  box-shadow: 0px 3px 5px -1px rgb(0 0 0 / 20%),
    0px 6px 10px 0px rgb(0 0 0 / 14%), 0px 1px 18px 0px rgb(0 0 0 / 12%);
  padding: 20px;
  border-radius: 8px;

  .title {
    margin: 16px 0;
    h4 {
      margin: 0;
      text-align: center;
      font-size: 20px;
      font-weight: 500;
    }
  }

  .inputWrapper {
    margin: 10px 0;
    display: flex;
    flex-direction: column;
  }

  .btnWrapper {
    margin-top: 16px;
    text-align: center;
    .loginBtn {
      width: 100%;
    }
  }

  .signupWrapper {
    display: flex;
    align-items: center;
    justify-content: center;
    margin-top: 16px;

    span {
      margin-right: 2px;
      font-size: 14px;
      color: gray;
      margin-top: 2px;
    }

    button {
      color: $blue;
      font-size: 14px;
      margin-bottom: 2px;
    }
  }
}
