@import "styles/index";

.page-loader {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: rgb(0 0 0 / 60%);
  z-index: 1;
  border-radius: 8px;

  .loading {
    margin: 100px auto;
    font-size: 15px;
  }
}

.loading {
  margin: 10px auto;
  font-size: 4px;
  width: 1em;
  height: 1em;
  border-radius: 50%;
  position: relative;
  text-indent: -9999em;
  -webkit-animation: load5 1.1s infinite ease;
  animation: load5 1.1s infinite ease;
  -webkit-transform: translateZ(0);
  -ms-transform: translateZ(0);
  transform: translateZ(0);

  &.light {
    --loading-color-1: white;
    --loading-color-2: 255, 255, 255;
    --loading-color-3: rgba(255, 255, 255, 0.2);
  }

  --loading-color-1: #{$purple-1};
  --loading-color-2: 106, 57, 246;
  --loading-color-3: rgba(0, 255, 255, 0.2);
}

@-webkit-keyframes load5 {
  0%,
  100% {
    box-shadow: 0em -2.6em 0em 0em $loading-color-1,
      1.8em -1.8em 0 0em rgba($loading-color-2, 0.2),
      2.5em 0em 0 0em rgba($loading-color-2, 0.2),
      1.75em 1.75em 0 0em rgba($loading-color-2, 0.2),
      0em 2.5em 0 0em rgba($loading-color-2, 0.2),
      -1.8em 1.8em 0 0em rgba($loading-color-2, 0.2),
      -2.6em 0em 0 0em rgba($loading-color-2, 0.5),
      -1.8em -1.8em 0 0em rgba($loading-color-2, 0.7);
  }
  12.5% {
    box-shadow: 0em -2.6em 0em 0em rgba($loading-color-2, 0.7),
      1.8em -1.8em 0 0em $loading-color-1,
      2.5em 0em 0 0em rgba($loading-color-2, 0.2),
      1.75em 1.75em 0 0em rgba($loading-color-2, 0.2),
      0em 2.5em 0 0em rgba($loading-color-2, 0.2),
      -1.8em 1.8em 0 0em rgba($loading-color-2, 0.2),
      -2.6em 0em 0 0em rgba($loading-color-2, 0.2),
      -1.8em -1.8em 0 0em rgba($loading-color-2, 0.5);
  }
  25% {
    box-shadow: 0em -2.6em 0em 0em rgba($loading-color-2, 0.5),
      1.8em -1.8em 0 0em rgba($loading-color-2, 0.7),
      2.5em 0em 0 0em $loading-color-1,
      1.75em 1.75em 0 0em rgba($loading-color-2, 0.2),
      0em 2.5em 0 0em rgba($loading-color-2, 0.2),
      -1.8em 1.8em 0 0em rgba($loading-color-2, 0.2),
      -2.6em 0em 0 0em rgba($loading-color-2, 0.2),
      -1.8em -1.8em 0 0em rgba($loading-color-2, 0.2);
  }
  37.5% {
    box-shadow: 0em -2.6em 0em 0em rgba($loading-color-2, 0.2),
      1.8em -1.8em 0 0em rgba($loading-color-2, 0.5),
      2.5em 0em 0 0em rgba($loading-color-2, 0.7),
      1.75em 1.75em 0 0em $loading-color-1,
      0em 2.5em 0 0em rgba($loading-color-2, 0.2),
      -1.8em 1.8em 0 0em rgba($loading-color-2, 0.2),
      -2.6em 0em 0 0em rgba($loading-color-2, 0.2),
      -1.8em -1.8em 0 0em rgba($loading-color-2, 0.2);
  }
  50% {
    box-shadow: 0em -2.6em 0em 0em rgba($loading-color-2, 0.2),
      1.8em -1.8em 0 0em rgba($loading-color-2, 0.2),
      2.5em 0em 0 0em rgba($loading-color-2, 0.5),
      1.75em 1.75em 0 0em rgba($loading-color-2, 0.7),
      0em 2.5em 0 0em $loading-color-1,
      -1.8em 1.8em 0 0em rgba($loading-color-2, 0.2),
      -2.6em 0em 0 0em rgba($loading-color-2, 0.2),
      -1.8em -1.8em 0 0em rgba($loading-color-2, 0.2);
  }
  62.5% {
    box-shadow: 0em -2.6em 0em 0em rgba($loading-color-2, 0.2),
      1.8em -1.8em 0 0em rgba($loading-color-2, 0.2),
      2.5em 0em 0 0em rgba($loading-color-2, 0.2),
      1.75em 1.75em 0 0em rgba($loading-color-2, 0.5),
      0em 2.5em 0 0em rgba($loading-color-2, 0.7),
      -1.8em 1.8em 0 0em $loading-color-1,
      -2.6em 0em 0 0em rgba($loading-color-2, 0.2),
      -1.8em -1.8em 0 0em rgba($loading-color-2, 0.2);
  }
  75% {
    box-shadow: 0em -2.6em 0em 0em rgba($loading-color-2, 0.2),
      1.8em -1.8em 0 0em rgba($loading-color-2, 0.2),
      2.5em 0em 0 0em rgba($loading-color-2, 0.2),
      1.75em 1.75em 0 0em rgba($loading-color-2, 0.2),
      0em 2.5em 0 0em rgba($loading-color-2, 0.5),
      -1.8em 1.8em 0 0em rgba($loading-color-2, 0.7),
      -2.6em 0em 0 0em $loading-color-1,
      -1.8em -1.8em 0 0em rgba($loading-color-2, 0.2);
  }
  87.5% {
    box-shadow: 0em -2.6em 0em 0em rgba($loading-color-2, 0.2),
      1.8em -1.8em 0 0em rgba($loading-color-2, 0.2),
      2.5em 0em 0 0em rgba($loading-color-2, 0.2),
      1.75em 1.75em 0 0em rgba($loading-color-2, 0.2),
      0em 2.5em 0 0em rgba($loading-color-2, 0.2),
      -1.8em 1.8em 0 0em rgba($loading-color-2, 0.5),
      -2.6em 0em 0 0em rgba($loading-color-2, 0.7),
      -1.8em -1.8em 0 0em $loading-color-1;
  }
}
@keyframes load5 {
  0%,
  100% {
    box-shadow: 0em -2.6em 0em 0em $loading-color-1,
      1.8em -1.8em 0 0em rgba($loading-color-2, 0.2),
      2.5em 0em 0 0em rgba($loading-color-2, 0.2),
      1.75em 1.75em 0 0em rgba($loading-color-2, 0.2),
      0em 2.5em 0 0em rgba($loading-color-2, 0.2),
      -1.8em 1.8em 0 0em rgba($loading-color-2, 0.2),
      -2.6em 0em 0 0em rgba($loading-color-2, 0.5),
      -1.8em -1.8em 0 0em rgba($loading-color-2, 0.7);
  }
  12.5% {
    box-shadow: 0em -2.6em 0em 0em rgba($loading-color-2, 0.7),
      1.8em -1.8em 0 0em $loading-color-1,
      2.5em 0em 0 0em rgba($loading-color-2, 0.2),
      1.75em 1.75em 0 0em rgba($loading-color-2, 0.2),
      0em 2.5em 0 0em rgba($loading-color-2, 0.2),
      -1.8em 1.8em 0 0em rgba($loading-color-2, 0.2),
      -2.6em 0em 0 0em rgba($loading-color-2, 0.2),
      -1.8em -1.8em 0 0em rgba($loading-color-2, 0.5);
  }
  25% {
    box-shadow: 0em -2.6em 0em 0em rgba($loading-color-2, 0.5),
      1.8em -1.8em 0 0em rgba($loading-color-2, 0.7),
      2.5em 0em 0 0em $loading-color-1,
      1.75em 1.75em 0 0em rgba($loading-color-2, 0.2),
      0em 2.5em 0 0em rgba($loading-color-2, 0.2),
      -1.8em 1.8em 0 0em rgba($loading-color-2, 0.2),
      -2.6em 0em 0 0em rgba($loading-color-2, 0.2),
      -1.8em -1.8em 0 0em rgba($loading-color-2, 0.2);
  }
  37.5% {
    box-shadow: 0em -2.6em 0em 0em rgba($loading-color-2, 0.2),
      1.8em -1.8em 0 0em rgba($loading-color-2, 0.5),
      2.5em 0em 0 0em rgba($loading-color-2, 0.7),
      1.75em 1.75em 0 0em $loading-color-1,
      0em 2.5em 0 0em rgba($loading-color-2, 0.2),
      -1.8em 1.8em 0 0em rgba($loading-color-2, 0.2),
      -2.6em 0em 0 0em rgba($loading-color-2, 0.2),
      -1.8em -1.8em 0 0em rgba($loading-color-2, 0.2);
  }
  50% {
    box-shadow: 0em -2.6em 0em 0em rgba($loading-color-2, 0.2),
      1.8em -1.8em 0 0em rgba($loading-color-2, 0.2),
      2.5em 0em 0 0em rgba($loading-color-2, 0.5),
      1.75em 1.75em 0 0em rgba($loading-color-2, 0.7),
      0em 2.5em 0 0em $loading-color-1,
      -1.8em 1.8em 0 0em rgba($loading-color-2, 0.2),
      -2.6em 0em 0 0em rgba($loading-color-2, 0.2),
      -1.8em -1.8em 0 0em rgba($loading-color-2, 0.2);
  }
  62.5% {
    box-shadow: 0em -2.6em 0em 0em rgba($loading-color-2, 0.2),
      1.8em -1.8em 0 0em rgba($loading-color-2, 0.2),
      2.5em 0em 0 0em rgba($loading-color-2, 0.2),
      1.75em 1.75em 0 0em rgba($loading-color-2, 0.5),
      0em 2.5em 0 0em rgba($loading-color-2, 0.7),
      -1.8em 1.8em 0 0em $loading-color-1,
      -2.6em 0em 0 0em rgba($loading-color-2, 0.2),
      -1.8em -1.8em 0 0em rgba($loading-color-2, 0.2);
  }
  75% {
    box-shadow: 0em -2.6em 0em 0em rgba($loading-color-2, 0.2),
      1.8em -1.8em 0 0em rgba($loading-color-2, 0.2),
      2.5em 0em 0 0em rgba($loading-color-2, 0.2),
      1.75em 1.75em 0 0em rgba($loading-color-2, 0.2),
      0em 2.5em 0 0em rgba($loading-color-2, 0.5),
      -1.8em 1.8em 0 0em rgba($loading-color-2, 0.7),
      -2.6em 0em 0 0em $loading-color-1,
      -1.8em -1.8em 0 0em rgba($loading-color-2, 0.2);
  }
  87.5% {
    box-shadow: 0em -2.6em 0em 0em rgba($loading-color-2, 0.2),
      1.8em -1.8em 0 0em rgba($loading-color-2, 0.2),
      2.5em 0em 0 0em rgba($loading-color-2, 0.2),
      1.75em 1.75em 0 0em rgba($loading-color-2, 0.2),
      0em 2.5em 0 0em rgba($loading-color-2, 0.2),
      -1.8em 1.8em 0 0em rgba($loading-color-2, 0.5),
      -2.6em 0em 0 0em rgba($loading-color-2, 0.7),
      -1.8em -1.8em 0 0em $loading-color-1;
  }
}
