@import "styles/index";

.container {
  background: $background;
  // min-height: 100vh;
  display: flex;
  position: relative;
  border-radius: 6px;
  overflow-x: hidden;

  .settingBtn {
    position: absolute;
    top: 16px;
    right: 16px;
    display: flex;
    align-items: center;
    justify-content: center;
    border: none;
    background-color: $blue;
    color: white;
    padding: 6px;
    border-radius: 4px;
  }
}

.wrapper {
  .body {
    width: 100%;
  }
}

.body {
  padding: 0;
  width: 80%;
}