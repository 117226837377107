@import "styles/index";

.container {
  color: $black;
  font-size: 16px;
  text-align: center;

  .uploadWrapper {
    padding: 48px 25px;
    box-shadow: rgb(0 0 0 / 20%) 0px 3px 5px -1px,
      rgb(0 0 0 / 14%) 0px 6px 10px 0px, rgb(0 0 0 / 12%) 0px 1px 18px 0px;
  }

  .tableWrapper {
    height: calc(100vh - 398px);
  }
}
