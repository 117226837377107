@import "styles/index";

.inviteWrapper {
  // float: right;
  display: inline-block;
}

.titleWrapper {
  display: flex;
  justify-content: space-between;
}

.container {
  color: $black;
  font-size: 16px;

  .title {
    padding: 16px;
    color: #4e4e50;
    font-weight: 600;
    text-align: left;
    font-size: 20px;
  }

  .profileCard {
    box-shadow: rgb(0 0 0 / 20%) 0px 3px 5px -1px,
      rgb(0 0 0 / 14%) 0px 6px 10px 0px, rgb(0 0 0 / 12%) 0px 1px 18px 0px;

    display: flex;
    margin: 0 16px;
    padding: 24px;
    border-radius: 8px;
    overflow: auto;

    .image {
      border-radius: 8px;
      width: 150px;
      height: 150px;
      border-radius: 8px;
    }

    .iconWrapper {
      color: $border-gray;
      display: flex;
      align-items: center;
      svg {
        width: 150px;
        height: 150px;
      }
    }

    .info {
      flex: 1;
      margin-right: 16px;
      display: flex;
      flex-direction: column;
      justify-content: space-around;
      .item {
        display: flex;
        align-items: center;
        font-size: 16px;
        color: #767676;

        .label {
          width: 110px;
          text-align: right;
          margin-right: 16px;
          font-weight: 600;
        }

        .value {
          flex: 1;
          font-weight: 300;
          text-align: left;
        }
      }
    }

    .btns {
      display: flex;
      flex-direction: column;
      justify-content: space-between;
      align-items: flex-end;

      .iconBtn {
        border: none;
        width: 30px;
        height: 30px;
        cursor: pointer;
        font-size: 20px;
        border-radius: 6px;
      }
    }
  }
}

.loginForm {
  display: flex;
  flex-direction: column;
  box-shadow: 0px 3px 5px -1px rgb(0 0 0 / 20%),
    0px 6px 10px 0px rgb(0 0 0 / 14%), 0px 1px 18px 0px rgb(0 0 0 / 12%);
  padding: 20px;
  border-radius: 8px;

  .title {
    h4 {
      margin: 0;
      text-align: center;
      font-size: 20px;
      font-weight: 500;
    }
  }

  .inputWrapper {
    margin: 10px 0;
    display: flex;
    flex-direction: column;
  }

  .btnWrapper {
    margin-top: 16px;
    text-align: center;
    .loginBtn {
      width: 100%;
    }
  }

  .signupWrapper {
    display: flex;
    align-items: center;
    justify-content: center;
    margin-top: 16px;

    span {
      margin-right: 2px;
      font-size: 14px;
      color: gray;
      margin-top: 2px;
    }

    button {
      color: $blue;
      font-size: 14px;
      margin-bottom: 2px;
    }
  }

  .nameWrapper {
    display: flex;

    .inputWrapper {
      max-width: 150px;

      &:last-child {
        margin-left: 10px;
      }

      input {
        min-width: unset;
      }
    }
  }
}

.regionsTable {
  box-shadow: rgb(0 0 0 / 20%) 0px 3px 5px -1px,
    rgb(0 0 0 / 14%) 0px 6px 10px 0px, rgb(0 0 0 / 12%) 0px 1px 18px 0px;

  display: flex;
  margin: 0 16px;
  padding: 24px;
  border-radius: 8px;
  margin-bottom: 32px;
  position: relative;

  .table {
    height: unset;
  }
}


.membersTable {
  box-shadow: rgb(0 0 0 / 20%) 0px 3px 5px -1px,
    rgb(0 0 0 / 14%) 0px 6px 10px 0px, rgb(0 0 0 / 12%) 0px 1px 18px 0px;

  display: flex;
  margin: 0 16px;
  padding: 24px;
  border-radius: 8px;
  margin-bottom: 32px;
  position: relative;

  .table {
    height: unset;
  }
}