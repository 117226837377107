@import "styles/index";

.container {
  color: $black;
  font-size: 16px;
  text-align: left;
  // box-sizing: border-box;

  .header {
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 16px;
    color: #4e4e50;
    box-shadow: rgb(0 0 0 / 20%) 0px 3px 5px -1px,
      rgb(0 0 0 / 14%) 0px 6px 10px 0px, rgb(0 0 0 / 12%) 0px 1px 18px 0px;

    .title {
      font-weight: 500;
    }

    .right {
      [class~="underline-btn"] {
        margin-right: 40px;
      }
      [class~="primary-btn"] {
        margin-left: 20px;
      }
    }
  }

  .body {
    padding-top: 16px;
    overflow: auto;
    height: calc(100vh - 80px);
    .check {
      // display: flex;
      // justify-content: flex-start;
      padding: 8px;
      input {
        width: 45px;
      }
      label {
        margin-left: 8px;
        color: #4e4e50;
      }
    }
  }
}
