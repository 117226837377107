@import "styles/index";

.container {
  .btn {
    background-color: $blue;
    border: none;
    border-radius: 4px;
    color: white;
    padding: 10px 20px;
    font-size: 14px;
  }
}
