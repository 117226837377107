@import url("https://fonts.googleapis.com/css2?family=Poppins:wght@400;700&display=swap");
@import "styles/index";

@import "/node_modules/react-grid-layout/css/styles.css";
@import "/node_modules/react-resizable/css/styles.css";
// global styles

body {
  margin: 0;
  font-family: "Poppins";
}

.pagination {
  display: flex;
  list-style-type: none;
  padding: 0;

  li {
    a {
      padding: 4px 12px;
      cursor: pointer;
    }

    &.selected {
      a {
        background-color: white;
        border-radius: 4px;
        box-shadow: 0px 3px 5px -1px rgb(0 0 0 / 20%),
          0px 6px 10px 0px rgb(0 0 0 / 14%), 0px 1px 18px 0px rgb(0 0 0 / 12%);
      }
    }
  }
}

.dropdown {
  select,
  span {
    color: #646669;
    font-size: 16px;
  }

  span {
    margin-right: 16px;
  }

  select {
    border: none;
    padding: 8px;
    background-color: white;
    border-radius: 6px;
    border: 1px solid #f7f8fa;
    &:focus {
      outline: none;
    }
  }
}

.primary-btn {
  background-color: $blue;
  border: none;
  border-radius: 4px;
  color: white;
  padding: 10px 20px;
  font-size: 16px;
  cursor: pointer;
}

.primary-btn-sm {
  background-color: $blue;
  border: none;
  border-radius: 4px;
  color: white;
  padding: 4px 4px;
  font-size: 10px;
  cursor: pointer;
}

.underline-btn {
  border: none;
  background-color: transparent;
  text-decoration: underline;
  color: #777a7e;
  font-size: 16px;
  cursor: pointer;
}

.text-input {
  border-radius: 4px;
  border: 1px solid #e3e9ef;
  font-size: 16px;
  color: #524d4d;
  padding: 8px 12px;
  min-width: 300px;
  &:focus {
    outline: none;
    border: 1px solid $blue;
  }
}

.inputLabel {
  font-size: 14px;
  color: gray;
}


.inputError {
  color: #d32f2f;
  font-size: 12px;
  text-align: left;
}


.critical {
  border-left: 8px solid #dc3545;
}
.high {
  border-left: 8px solid #ffc107;
}

.medium {
  border-left: 8px solid #17a2b8;
}

.low {
  border-left: 8px solid #28a745;
}


.bodyFull {
  width: 100%;
}