@use "sass:math";

:root {
  --vh: 1vh;
}

// --------- COLORS
$white: #fff;
$dark-white: #bccfe3;
$dark-gray: #5e6a8f;
$light-gray: #92a5bc;
$border-gray: #a9bad7;
$medium-gray: #b8c6de;
$border-gray-2: #cfd6e2;
$slider-track-gray: #d3dde9;
$slider-thumb: #777e9c;
$slider-background: #d9e3ef50;
$background: #ecf1f6;
$background-blue: #f2f8ff;

$text-white: #e7eff8;
$black: #202945;
$blue: #238bff;
$red: #d20000;
$dark-blue: #2f3b58;
$orange: #f47921;

$table-bg-color: transparent;
$table-border: none;
$menu-bg-color: white;

// --------- FONTS
$raleway: "Raleway", sans-serif;
$roboto: "Roboto", sans-serif;

// --------- SPACINGS
$spacing-95: 56px;

// --------- GRID
$grid-horizontal-padding: $spacing-95;
$grid-gutter: 1px;
$grid-col-count: 24;
$grid-min-width: 1200px;
$breakpoint-lg: 1600px;

// --------- CUSTOM WIDTHS

$content-right-padding: 2.5rem;
$content-left-padding: 1.5rem;

// --------- LAYERS

$layer-0: 0;
$layer-1: 100;
$layer-2: 200;
$layer-3: 300;
$layer-4: 400;
$layer-5: 500;
$layer-6: 600;
$layer-7: 700;
$layer-8: 800;
$layer-9: 900;
$layer-10: 1000;

// --------- ANIMATIONS

$easing-standard-productive: cubic-bezier(0.2, 0, 0.38, 0.9);
$easing-entrance-expressive: cubic-bezier(0, 0, 0.3, 1);
$easing-exit-expressive: cubic-bezier(0.4, 0.14, 1, 1);

$move-up-fade-in-offset: 4rem;

$duration-1: 110ms;
$duration-2: 220ms;
$duration-3: 330ms;

$shadow: 0 0.4rem 1.2rem #0000001c;
$shadow-up: 0 -0.4rem 1.2rem #0000001c;

$border-radius-small: 0.5rem;
$border-circle: 9999px;

$logo-banner-height: 64px;
