@import "styles/index";


.deviceForm {
  padding-right: 30px;
  padding-bottom: 30px;
}

.close {
  border: 1px solid gray;
  color: #333;
  font-size:12px;
  text-align: right;
  margin-top: 20px;
  margin-right: 20px;
  padding: 0px 6px;
  border-radius: 36px;
  cursor: pointer;
  position: absolute;
  right: 0;
}

.selectField {
  width: 325px;
  text-align: left;
}

.inputWrapper {
  margin: 10px 0;
  display: flex;
  flex-direction: column;
  .inputField {
    label {
      width: 130px;
      text-align: right;
      margin-right: 10px;
      align-self: center;
    }
    display: flex;
    flex-direction: row;
  }
}

.wrapper {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  display: none;
  justify-content: center;
  align-items: center;
  background-color: rgba(0, 0, 0, 0.5);
  z-index: 3;

  &[class~="shown"] {
    display: flex;
  }

  .body {
    border-radius: 8px;
    background-color: $menu-bg-color;
    position: relative;
  }
}
