@import "styles/index";


.profileEditForm {
  padding: 0 30px 30px 30px;
  h4 {
    text-align: center;
  }

  .image {
    margin: 0 auto;
    height: 200px;
    height: 200px;
    width: 200px;
    border: 1px solid #e3e9ef;
    border-radius: 6px;
    overflow: hidden;
    color: #a9bad7;
    display: flex;
    align-items: center;
    position: relative;
    svg {
      height: 100%;
      width: 100%;
    }
    img {
      width: 100%;
    }
  }

  input[type="file"] {
    cursor: pointer;
    position: absolute;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    opacity: 0;
  }

  .btnWrapper {
    text-align: center;
  }
}

.close {
  border: 1px solid gray;
  color: #333;
  font-size:12px;
  text-align: right;
  margin-top: 20px;
  margin-right: 20px;
  padding: 0px 6px;
  border-radius: 36px;
  cursor: pointer;
  position: absolute;
  right: 0;
}

.inputWrapper {
  margin: 10px 0;
  display: flex;
  flex-direction: column;
  padding: 0 20px;
  .inputField {
    label {
      width: 130px;
      text-align: right;
      margin-right: 10px;
      align-self: center;
    }
    display: flex;
    flex-direction: row;
  }
}
