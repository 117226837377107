@import "styles/index";

.regionSelect {
  height: 35px;
}

.container {
  color: $black;
  font-size: 16px;
  text-align: center;
  width: 100%;
  .top {
    padding: 16px 24px;
    display: flex;
    box-shadow: 0px 8px 14px -3px rgba(0, 0, 0, 0.46);
    -webkit-box-shadow: 0px 8px 14px -3px rgba(0, 0, 0, 0.46);
    -moz-box-shadow: 0px 8px 14px -3px rgba(0, 0, 0, 0.46);
    .file {
      flex: 1;
      margin-right: 48px;
    }
    .chartsRoot {
      flex: 2;

      display: flex;
      flex-direction: column;
      justify-content: space-between;

      .filter {
        display: flex;
        align-items: center;
        .title {
          color: $black;
          font-size: 14px;
          padding-right: 16px;
          border-right: 1px solid $light-gray;
        }
        .dropdown {
          padding-left: 16px;
          display: flex;
          align-items: center;
          color: $dark-gray;
          font-size: 14px;
          color: $black;

          select {
            color: $black;
            font-size: 14px;
            margin-left: 6px;
            border-radius: 4px;
            padding: 4px;
            border: 1px solid $light-gray;

            &:focus {
              outline: none;
            }
          }
        }
      }

      .charts {
        display: flex;
        padding-bottom: 24px;
        width: 100%;
        .chart {
          width: 25%;
          position: relative;
          .labelRoot {
            position: absolute;
            top: 0;
            left: 0;
            width: 100%;
            height: 100%;
            display: flex;
            justify-content: center;
            align-items: center;
            flex-direction: column;
            .label {
              color: $black;
              font-size: 12px;
            }
            .value {
              color: $black;
              font-size: 24px;
              margin-top: 4px;
            }
          }
        }
      }
    }
  }

  .visibleWrapper {
    margin-top: 20px;
    padding: 0 10px;
    .label {
      margin-right: 8px;
      font-size: 14px;
      .input {
        margin-right: 8px;
      }
    }
  }

  .body {
    padding: 20px 0;
    width: 100%;
    .pieRoot {
      display: flex;
      flex-direction: column;
      align-items: flex-start;
      align-items: center;

      .chart {
        margin-top: 10px;
      }

      .title {
        color: $black;
        font-size: 14px;
        margin-bottom: 16px;
        width: 100%;
        text-align: left;
      }
    }
  }
}
