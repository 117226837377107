@import "styles/index";

.findingsWrapper {
  margin-left: 20%;

  .close {
    margin-left: 20px;
    border: 1px solid gray;
    color: #333;
    font-size:12px;
    text-align: right;
    float: right;
    padding: 0px 6px;
    border-radius: 36px;
    cursor: pointer;
  }

  .tableWrapper {
    box-sizing: border-box;
    width: 100%;
    height: 100%;
    position: relative;
    .header {
      background-color: $background;
      display: flex;
      align-items: center;
      padding: 16px;
      z-index: 1;
      box-shadow: rgba(0, 0, 0, 0.2) 0px 3px 5px -1px, rgba(0, 0, 0, 0.14) 0px 6px 10px 0px, rgba(0, 0, 0, 0.12) 0px 1px 18px 0px;

      .search {
        display: flex;
        align-items: center;
        padding: 8px;
        background-color: white;
        border-radius: 6px;
        color: #adbbcd;
        font-size: 20px;
        border: 1px solid #f7f8fa;

        input {
          border: none;
          font-size: 16px;
          margin-left: 12px;
          ::placeholder {
            color: #adbbcd;
          }
          &:focus {
            outline: none;
          }
        }
      }

      .divider {
        width: 2px;
        height: 24px;
        background-color: white;
        margin: 0 32px;
      }
    }
  }

  .tableContainer {
    background-color: $background;
    position: relative;
    height: calc(100vh - 127px);
    // min-height: 500px;
    overflow: auto;
    box-sizing: border-box;
    &::before {
      display: none;
      content: "";
      width: 100%;
      height: 57px;
      position: absolute;
      left: 0;
      top: 0;
      background-color: transparent;
      z-index: 2;
      box-shadow: rgb(0 0 0 / 20%) 0px 3px 5px -1px,
        rgb(0 0 0 / 14%) 0px 6px 10px 0px, rgb(0 0 0 / 12%) 0px 1px 18px 0px;
    }
    .table {
      width: calc(100% - 32px);
      margin: 0 16px;
      color: $black;
      border-spacing: 0 16px;
      border-collapse: separate;
      position: relative;

      thead {
        tr {
          border-radius: 8px;
          box-shadow: rgb(0 0 0 / 20%) 0px 3px 5px -1px,
            rgb(0 0 0 / 14%) 0px 6px 10px 0px, rgb(0 0 0 / 12%) 0px 1px 18px 0px;
          position: sticky;
          top: 0px;

          th {
            &:first-child {
              border-top-left-radius: 8px;
              border-bottom-left-radius: 8px;
            }
            &:last-child {
              border-bottom-right-radius: 8px;
              border-top-right-radius: 8px;
            }
          }
        }
      }

      tbody {
        tr {
          box-shadow: 0px 2px 1px -1px rgb(0 0 0 / 20%),
            0px 1px 1px 0px rgb(0 0 0 / 14%), 0px 1px 3px 0px rgb(0 0 0 / 12%);
        }
      }

      tr {
        border-radius: 8px;
      }

      th,
      td {
        text-align: left;
        font-size: 16px;
        padding: 16px;
        img {
          vertical-align: inherit;
          margin-right: 8px;
          margin-bottom: 4px;
        }
      }

      th {
        text-align: left;
        font-weight: bold;
        background-color: #ecf1f6;
        color: #616263;

        &:last-child {
          text-align: right;
        }
      }

      td {
        background-color: white;
        color: #686868;
        text-align: left;
        border-top: $table-border;
        border-bottom: $table-border;

        &:first-child {
          border-top-left-radius: 8px;
          border-bottom-left-radius: 8px;
          text-align: left;
          border-right: 1px solid transparent;
        }
        &:last-child {
          border-bottom-right-radius: 8px;
          border-top-right-radius: 8px;
          text-align: right;
        }

        &.actions {
          .more {
            display: flex;
            align-items: center;
          }

          .menus {
            position: absolute;
            display: none;
            &[class~="shown"] {
              display: block;
              z-index: 1;
              right: 20px;
              min-width: 100px;
              border-radius: 8px;
              box-shadow: rgb(0 0 0 / 20%) 0px 5px 5px -3px,
                rgb(0 0 0 / 14%) 0px 8px 10px 1px,
                rgb(0 0 0 / 12%) 0px 3px 14px 2px;
              background: $menu-bg-color;
              padding: 16px;

              &[class~="shown"] {
                right: unset;
                left: 20px;
              }
            }
            .item {
              padding: 4px;
              cursor: pointer;
              display: flex;
              align-items: center;
              svg {
                margin-right: 6px;
              }
              &:hover {
                font-weight: bold;
              }
            }
          }

          .singleAction {
            display: flex;
            align-items: center;
            justify-content: flex-end;
            cursor: pointer;
            svg {
              margin-right: 6px;
            }
          }
        }
      }

      .icon {
        width: 30px;
        height: 30px;
      }
    }
  }

  .footer {
    background-color: $background;
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 0px 16px;

    box-shadow: rgb(0 0 0 / 20%) 0px 3px 5px -1px,
      rgb(0 0 0 / 14%) 0px 6px 10px 0px, rgb(0 0 0 / 12%) 0px 1px 18px 0px;
  }

}