@import "styles/index";

.navigation {
  position: sticky;
  top: 0;
  width: "20%";
  height: 100vh;
  flex-shrink: 0;
  background-color: $black;
}

.content {
  width: 100%;
  min-height: 100vh;
  background-color: $background;
}

.heading {
  color: #bccfe3;
  font-family: "Raleway", sans-serif;
  font-style: normal;
  line-height: 24px;
  font-size: 20px;
  font-weight: normal;
  letter-spacing: 3px;
  text-transform: uppercase;
}

.before {
  position: absolute;
  left: 0;
  width: 33px;
  border-bottom: 1px solid $orange;
}

.banner {
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 32px 22px 50px 45px;
}

@media (max-width: 767px) {
  .banner {
    padding: 32px 22px 50px 5px;
  }

  .before {
    display: none;
  }
}


.navItemsContainer {
  display: flex;
  height: calc(100vh - 106px); // title height
  flex-direction: column;
  justify-content: space-between;
}

.navItemText {
  position: relative;
  color: #5e6a8f;
  padding-left: 12px;
  font-family: "Roboto", sans-serif;
  font-size: 14px;
  font-weight: normal;
  letter-spacing: 0.35px;
  line-height: 20px;
}

@media (max-width: 767px) {
  .navItemText {
    display: none;
  }
}


.navItemIconWrapper {
  display: flex;
  height: 30px;
  align-items: center;
  justify-content: center;
  padding-right: 8px;
  padding-left: 15px;
  border-bottom-right-radius: $border-radius-small;
  border-top-right-radius: $border-radius-small;
}

.innerIconWrapper {
  position: relative;
  display: flex;
  width: 23px;
  align-items: center;
  justify-content: center;
}

@media (max-width: 767px) {
  .innerIconWrapper {
    width: 55px;
  }
}

.navItem {
  display: flex;
  align-items: center;
  padding: 7px 0;
  color: $dark-gray;
  opacity: 1;
  transition: opacity $duration-1;
  text-decoration: none;

  &:hover {
    opacity: 0.8;
  }
}

.navItemText {
  color: $dark-gray;
}

.navItemActive {
  color: $white;

  .navItemIconWrapper {
    background-color: $orange;
    color: $white;
  }

  .navItemText {
    color: $border-gray;

    &::after {
      position: absolute;
      right: -10px;
      bottom: 0;
      width: 100%;
      height: 1px;
      box-sizing: content-box;
      padding-right: 10px;
      background-color: $orange;
      content: "";
    }
  }
}

.userInfo {
  display: flex;
  flex-direction: column;
  padding: 0 15px;
}

.divider {
  width: 100%;
  height: 1px;
  background-color: $dark-gray;
}

.userInfoInner {
  display: flex;
  align-items: center;
  padding: 20px 0;
}

.image {
  width: 33px;
  height: 33px;
  border-radius: 8px;
  margin-right: 16px;
  object-fit: cover;
}

.iconWrapper {
  color: $border-gray;
  margin-right: 16px;
  display: flex;
  align-items: center;
  svg {
    width: 24px;
    height: 24px;
  }
}

.userWrapper {
  display: flex;
  justify-content: space-between;
  align-items: center;

  .logoutWrapper {
    color: $border-gray;
    display: flex;
    align-items: center;
    border: none;
    background-color: transparent;
    cursor: pointer;
    svg {
      width: 20px;
      height: 20px;
    }
  }
}

.userName {
  color: $border-gray;
  font-family: "Roboto", sans-serif;
  font-size: 14px;
  font-weight: normal;
  letter-spacing: 0.35px;
  line-height: 20px;
  text-overflow: ellipsis;
  overflow: hidden;
  white-space: nowrap;
}

@media (max-width: 767px) {
  .userName {
    display: none;
  }
}

.expandButton {
  position: absolute;
  right: 0;
  bottom: 120px;
  display: flex;
  width: 25px;
  height: 25px;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  background-color: #374361;
  border-bottom-left-radius: $border-radius-small;
  border-top-left-radius: $border-radius-small;
  color: $white;
  transition: background-color $duration-1;
  cursor: pointer;
  outline: none;
  border: none;

  &:hover {
    background-color: $orange;
  }

  svg {
    transform: rotate(90deg);
    transition: transform $duration-2;
  }
}

.expandButtonExpanded {
  svg {
    transform: rotate(270deg);
  }
}
