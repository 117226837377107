@import "styles/index";

.wrapper {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  display: none;
  justify-content: center;
  align-items: center;
  background-color: rgba(0, 0, 0, 0.5);
  z-index: 3;

  &[class~="shown"] {
    display: flex;
  }

  .body {
    border-radius: 8px;
    background-color: $menu-bg-color;
    position: relative;
  }
}
