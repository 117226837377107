@import "styles/index";

.container {
  display: flex;
  flex-direction: column;
  align-items: flex-start;

  .title {
    color: $black;
    font-size: 14px;
    margin-bottom: 16px;
  }

  .card {
    width: 100%;
    box-shadow: 0 3px 1px -2px rgb(0 0 0 / 20%), 0 2px 2px 0 rgb(0 0 0 / 14%),
      0 1px 5px 0 rgb(0 0 0 / 12%);
    background-color: white;
    .item {
      display: flex;
      align-items: center;
      justify-content: space-between;
      color: $dark-blue;
      font-size: 14px;
      padding: 10px 4px;
      .label {
        flex: 1;
        text-align: left;
        padding-left: 20px;
      }
      &:first-child {
        color: $black;
      }
    }
  }
}
